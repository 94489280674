<template>
	<div class="order_wrap fixed_wrap customer_area">
		<div class="txt_box_type02">
			<h1 class="tit_page" v-if="this.paymentLimit === ''">
				주문에 필요한 정보 입력이 <br />
				완료되었습니다.
			</h1>
			<h1 class="tit_page" v-else>
				주문에 필요한 인증이 <br />
				완료되었습니다.
			</h1>
			<div class="txt_body1">
				주문 접수에 다른 정보가 더 필요하다면<br />다시 연락 드리겠습니다.<br />
				감사합니다.
			</div>
			<div class="font_grey" v-if="this.paymentLimit === ''">
				※ 정보 수정이 필요하다면, 판매인에게 주문 진행여부를 먼저 <br />확인 후
				아래의 <b>'입력정보 수정하기'</b>를 통해 수정해 주세요.
			</div>
		</div>
		<div class="btn_align mt-8" v-if="this.paymentLimit === ''">
			<v-btn
				depressed
				height="20"
				color=""
				class="btn_underline my-6"
				@click="modiInfo"
			>
				입력정보 수정하기
			</v-btn>
		</div>
	</div>
</template>

<script>
import { fetchWebOrderInfoById } from '@/apis/order.status.api'

export default {
	name: 'PayComplete',
	data() {
		return {
			dialog: false,
			paymentLimit: '',
			webOrderNo: this.$store.getters['verify/mobOrderNo']
		}
	},
	created() {
		this.paymentLimit = this.$route.params.paymentLimit ?? ''
	},
	methods: {
		async modiInfo() {
			const res = await fetchWebOrderInfoById(this.webOrderNo)
			this.$log(res.resultObject.statusCode)
			if (
				['108', '106', '109', '201', '202', '203'].includes(
					res.resultObject.statusCode
				)
			) {
				if (res.resultObject.statusCode === '203') {
					this.$router.push({ name: 'verify-cust-deleted' })
					return
				} else {
					this.$router.push({
						name: 'cust-payuncomplete',
						params: { status: res.resultObject.statusCode }
					})
				}
			} else {
				this.$router.push({
					name: 'cust-payadd',
					query: this.$store.getters['custom/retInfo']
				})
			}
		}
	}
}
</script>
